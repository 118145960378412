import { SessionType, SessionDefaultState } from '../types/SessionType'

import { Updater } from 'use-immer'

import { httpRequest } from './httpRequest'

export class SessionAPI {

  private sidKey = 'sid'

  private storage: Storage | undefined

  constructor(private apiBaseUrl: string, private updateSession: Updater<SessionType>) {

    // Note that window.localStorage won't exist on the server side.
    if (typeof(window) !== 'undefined' && window.localStorage) {
      this.storage = window.localStorage
    }
  }

  /** Attempt to log in with the given credentials. */
  public async login(email: string, orderId: string): Promise<SessionType | false> {

    try {

      this.updateSession((draft: SessionType) => { draft.loading = true })

      const body = {
        username: email,
        order_id: orderId,
      }

      const result = await httpRequest('POST', `${this.apiBaseUrl}/session`, body)

      if (result.status === 200) {

        const session: SessionType = await result.json()

        session.login_order_id = orderId

        this.storage && this.storage.setItem(this.sidKey, session.id)
        this.storage && this.storage.setItem('lastLoginDate', new Date().toISOString())

        this.updateSession(session)

        return session
      }

    } catch (err) {

      console.warn(err)
    }

    this.updateSession((draft: SessionType) => { draft.loading = false })

    this.storage && this.storage.removeItem(this.sidKey)

    return false
  }

  /** Check if we have an existing valid login session. */
  public async get(): Promise<SessionType | false> {

    const sessionId = this.storage && this.storage.getItem(this.sidKey)

    this.updateSession((draft: SessionType) => { draft.loading = false })
    if (!sessionId) return false

    try {

      this.updateSession((draft: SessionType) => { draft.loading = true })

      const result = await httpRequest('GET', `${this.apiBaseUrl}/session?extras=login_order_id`, false, sessionId)

      if (result.status === 200) {

        const session: SessionType = await result.json()

        if (session?.id === '') {
          this.updateSession((draft: SessionType) => { draft.loading = false })
          return false
        }
        this.updateSession(session)
        return session
      }

    } catch (err) {

      console.warn(err)
    }

    this.updateSession((draft: SessionType) => { draft.loading = false })
    return false
  }

  /** Log out - remove session id and set empty session. */
  public logout() {
    this.storage && this.storage.removeItem(this.sidKey)
    this.updateSession(SessionDefaultState)
  }

}
