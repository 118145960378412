import React, { useState, Dispatch, SetStateAction, createContext } from 'react';
import { HotelListType } from '../types/HotelType';

export const HotelsSearchRoomsContext = createContext({} as HotelsSearchRoomsContextType);

export type HotelsSearchRoomsContextType = {
  hotelsSearch:HotelListType[],
  filteredHotels:HotelListType[],
  setHotelsSearch: Dispatch<SetStateAction<HotelListType[]>>,
  setFilteredHotels:Dispatch<SetStateAction<HotelListType[]>>
}

export function HotelsContextProvider ({ children }: JSX.ElementChildrenAttribute) {

  const [hotelsSearch, setHotelsSearch] = useState<HotelListType[]>([])
  const [filteredHotels, setFilteredHotels] = useState<HotelListType[]>([])

  const hotelsContext:HotelsSearchRoomsContextType = {  hotelsSearch, setHotelsSearch, filteredHotels, setFilteredHotels };

  return (
    <HotelsSearchRoomsContext.Provider value={hotelsContext}>
      { children }
    </HotelsSearchRoomsContext.Provider>
  )
}
