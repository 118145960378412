import { PackageType, RoomType } from "./HotelType";

export interface CartType {
  meta: CartMeta;
  data: CartData;
  loading?: boolean;
}

export interface CartData {
  id: string,
  tickets: CartTicket[];
}

export interface CartTicket {
  cart_item_id:           string;
  ticket_id:              string;
  qty:                    number;
  data:                   CartTicketData;
  title:                  string;
  product_id:             string;
  product_destination_id: string;
  tags:                   string[];
  price:                  string;
  attributes:             { [key: number]: string[] };
  year:                   string;
  date:                   string;
  time:                   string;
}

export interface CartTicketData {
  attraction_id:        string;
  attraction_title:     string;
  model:                string;
  shippable:            string;
  category?:            string;
  price?:               string;
  room:                RoomType;
  room_rate_plan?:      string;
  room_package_type?:   string;
  date?:                Date;
  orig_type_ticket?:    string;
  type_ticket?:         string;
  title?:               string;
  price_option_amount?: number;
  attributes?:          { [key: string]: string[] };
  date_id?:             string;
  tags:                 string;
  offerType?:           string;
  unique_id?:           number;
  time?:                string;
  year?:                string;
}

export interface CartMeta {
  count:           number;
  total_count:     number;
  limit:           number;
  offset:          number;
  total_price:     string;
  sale_cur:        string;
  payment_options: CartPaymentOptions;
  checkout_rules:  CartCheckoutRules;
  user_id:         string;
  is_hotel_cart:   number;
}

export interface CartCheckoutRules {
  discounts:             CartDiscounts;
  show_delivery_address: number;
}

export interface CartDiscounts {
  enable_discounts:   number;
  override_hide_form: number;
}

export interface CartPaymentOptions {
  people:               number;
  qualify:              number;
  deposit_enabled:      number;
  deposit:              string;
  days:                 string;
  type:                 string;
  deposittotal:         string;
  message_available:    string;
  message_notavailable: string;
}

export interface AddToCartTicketType {
  ticketId: string,
  qty: number,
  date?: string, // eg. 2024-06-04
  dateId?: string | null,
}


export interface AddToCartHotelType {
  roomId: string,
  room_number: number,
  date?: string, // eg. 2024-06-04
  dateId?: string | null,
  packages: PackageType ,
  price: string,
}

export const CartDefaultState: CartType = {
  meta: {
    count: 0,
    total_count: 0,
    limit: 0,
    offset: 0,
    total_price: '',
    sale_cur: '',
    user_id: '',
    is_hotel_cart: 0,
    checkout_rules: {
      discounts: {
        enable_discounts: 0,
        override_hide_form: 0,
      },
      show_delivery_address: 0,
    },
    payment_options: {
      people: 0,
      qualify: 0,
      deposit_enabled: 0,
      deposit: '',
      days: '',
      type: '',
      deposittotal: '',
      message_available: '',
      message_notavailable: '',
    }
  },
  data: {
    id: '',
    tickets: [],
  },
  loading: false,
}

export type CartTicketCategory = 'tickets'|'expressPasses'|'extras'|'hotels'|'rooms'
