import React from 'react'

import { CartType, CartDefaultState } from '../types/CartType'
import { CartAPI } from '../api/CartAPI'

export type CartContextShape = { cartAPI: CartAPI | undefined, cart: CartType }

const cartContextValue: CartContextShape = { cartAPI: undefined, cart: CartDefaultState }

export const CartContext = React.createContext(cartContextValue)

