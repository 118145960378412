import { CartTicket } from '../types/CartType'
import { OrderType } from '../types/OrderType'
import { GoogleDataLayerEcommerceProduct, GoogleDataLayerEcommerceEventAddToCart, GoogleDataLayerEcommerceEventPurchase, GoogleDataLayerEcommerceActionField } from '../types/GoogleDataLayerEventType'


export class GoogleDataLayerAPI {

  private buildActionFieldProperty(order: OrderType){

    const orderTickets = order.order_tickets

    // Checking if the order is a hotel order
    let hotel_order = false;
    orderTickets.forEach((ticket) => {
      if(ticket.tags.includes('hotels')){
        hotel_order = true;
      }
    })

    const actionField: GoogleDataLayerEcommerceActionField = {
      id: order.id,
      revenue: order.total,
      paymentMethod: order.order_payments[0].method,
      bookingType: hotel_order ? 'hotel_order' : 'ticket_order',
      currency: order.currency,
      departureDate: order.departure_date
    }

    return actionField
  }

  private buildProductProperty(name: string, id: string, price: string, quantity: number, variant: string){

    const product: GoogleDataLayerEcommerceProduct = {
      name: name,
      id: id,
      price: price,
      quantity: quantity,
      variant: variant
    }

    return product
  }

  public logAddToCart(ticketsAdded: Array<{ ticket: CartTicket, qty: number }>) {

    const listOfTickets: Array<GoogleDataLayerEcommerceProduct> = []

    ticketsAdded.forEach((tickets) => {

      const ticket = tickets.ticket

      // If ticket is a hotel or ticket, assign the different name and variant names
      if(ticket.tags.includes('hotels')){
        const products = this.buildProductProperty(ticket.data.attraction_title, ticket.product_id, ticket.price, +tickets.qty, ticket.title)
        listOfTickets.push(products)
      } else {
        const products = this.buildProductProperty(ticket.title, ticket.product_id, ticket.price, +tickets.qty, `${ticket.title} ${ticket.data.type_ticket}`)
        listOfTickets.push(products)
      }
    })

    const event: GoogleDataLayerEcommerceEventAddToCart = {
      event: 'addToCart',
      ecommerce: {
        add: {
          products: listOfTickets
        }
      }
    }

    this.logEvent(event)
  }

  public logPurchaseEvent(order: OrderType){

    const actionField: GoogleDataLayerEcommerceActionField = this.buildActionFieldProperty(order)
    const listOfTickets: Array<GoogleDataLayerEcommerceProduct> = []
    const orderTickets = order.order_tickets
    orderTickets.forEach((tickets) => {

      // If ticket is a hotel or ticket, assign the different name and variant names
      if (tickets.tags.includes('hotels')) {
        const products = this.buildProductProperty(tickets.title, tickets.product_id, tickets.price, +tickets.qty, tickets.product_title)
        listOfTickets.push(products)
      } else {
        const products = this.buildProductProperty(tickets.product_title, tickets.product_id, tickets.price, +tickets.qty, `${tickets.title} ${tickets.type}`)
        listOfTickets.push(products)
      }
    })

    const event: GoogleDataLayerEcommerceEventPurchase = {
      event: 'purchase',
      ecommerce: {
        purchase: {
          actionField: actionField,
          products: listOfTickets
        }
      }
    }

    this.logEvent(event)
  }

  /** Add an "event" object to datalayer[]. */
  public logEvent(event: GoogleDataLayerEcommerceEventAddToCart | GoogleDataLayerEcommerceEventPurchase) {
    if (window) {
      window.dataLayer = window.dataLayer || {}
      window.dataLayer.push(event)
    }
  }
}

