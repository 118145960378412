import { useState, Dispatch, SetStateAction, createContext } from "react";
import { HotelSearchRoom } from "../types/HotelType";

export const HotelSearchContext = createContext({} as HotelSearchContextType);

export type HotelSearchContextType = {
    hotelSearchRooms: HotelSearchRoom[] ,
    setHotelSearchRooms:Dispatch<SetStateAction<HotelSearchRoom[]>>,
}

export function HotelSearchContextProvider({children}:JSX.ElementChildrenAttribute) {

    const [hotelSearchRooms, setHotelSearchRooms] = useState<HotelSearchRoom[]>(() => {
        if (typeof window !== 'undefined') {
            try {
                const roomsDataLocalStorage = window.localStorage.getItem('roomsData');
                return roomsDataLocalStorage ? JSON.parse(roomsDataLocalStorage) : [];
            }
            catch (error) {
                return [];
            }
        }
    });
    const hotelSearchRoomsContext:HotelSearchContextType = {  hotelSearchRooms, setHotelSearchRooms };

    return(
        <HotelSearchContext.Provider value={hotelSearchRoomsContext}>
            {children}
        </HotelSearchContext.Provider>
    )
}


