import React, { useMemo, PropsWithChildren } from "react";

import { useEffect } from 'react'
import { useImmer } from 'use-immer'

import { SessionDefaultState } from '../types/SessionType'
import { SessionAPI } from '../api/SessionAPI'
import { SessionContext, SessionContextShape } from '../contexts/SessionContext'

import { CartDefaultState } from '../types/CartType'
import { CartAPI } from '../api/CartAPI'
import { CartContext, CartContextShape } from '../contexts/CartContext'

import { YearContextProvider } from './YearContext'
import { DataLayerContextProvider, DataLayerContextShape } from './DataLayerContext'
import { HotelsContextProvider } from "./HotelSearchRoomsContextWrapper";
import { HotelSearchContextProvider } from "./HotelSearchContext"
import { DatesSearchContextProvider } from "./DatesSearchContext"
import { Hotel_SlugProvider } from "./Hotel_SlugContext";
import { ABContext, IsActiveTestType } from './ABContext';

import type { CroTest } from '../types/CroTestType'

type ContextWrapperProps = {
  dataLayerContextProps: DataLayerContextShape,
  isActiveTest: IsActiveTestType,
  allTests: CroTest[],
}

export function ContextWrapper({ dataLayerContextProps, isActiveTest, allTests, children }: PropsWithChildren<ContextWrapperProps>) {

  const [cart, setCart] = useImmer(CartDefaultState)
  const cartAPI = useMemo(() => new CartAPI(process.env.NEXT_PUBLIC_API_BASE_URL ?? '', setCart), [setCart])
  const cartContextValue: CartContextShape = { cartAPI, cart }
  useEffect(() => { cartAPI.get() }, [ cartAPI ])

  // Add session and session API to page props:
  const [session, setSession] = useImmer(SessionDefaultState)
  const sessionAPI = useMemo(() => new SessionAPI(process.env.NEXT_PUBLIC_API_BASE_URL ?? '', setSession), [setSession])
  const sessionContextValue: SessionContextShape = { sessionAPI, session }
  useEffect(() => { sessionAPI.get() }, [ sessionAPI ])

  return (
    <ABContext.Provider value={{ isActiveTest, allTests }}>
      <SessionContext.Provider value={sessionContextValue}>
        <Hotel_SlugProvider>
          <CartContext.Provider value={cartContextValue}>
            <YearContextProvider>
              <DatesSearchContextProvider>
                <HotelSearchContextProvider>
                  <HotelsContextProvider>
                    <DataLayerContextProvider {...dataLayerContextProps}>
                      {children}
                    </DataLayerContextProvider>
                  </HotelsContextProvider>
                </HotelSearchContextProvider>
              </DatesSearchContextProvider>
            </YearContextProvider>
          </CartContext.Provider>
        </Hotel_SlugProvider>
      </SessionContext.Provider>
    </ABContext.Provider>
  )
}

