import { subHours, differenceInDays } from 'date-fns'
import {CartTicket} from '../types/CartType';
import { DataLayerEventType } from '../types/DataLayerEventType'
import { HotelSearchRoom } from '../types/HotelType';
import { SessionType } from '../types/SessionType';

export type VisitsData = {
  numVisits: number,
  hasLoggedInBefore: boolean,
  daysSinceLastVisit: number,
  lastVisitDate: Date | undefined,
}

export class DataLayerAPI {

  // How many hours must pass before we conisider activity as a new "visit":
  private readonly maxVisitDurationHours = 2

  private storage: Storage | undefined;

  constructor() {

    // Note that window.localStorage won't exist on the server side.
    if (typeof(window) !== "undefined" && window.localStorage) {
      this.storage = window.localStorage
    }
  }

  /*************************************************************
   * Visits.
   *************************************************************/

  public logVisit(): void {

    if (this.storage) {

      const lastVisitDate = this.getLastVisitDate()

      if (lastVisitDate) {

        if (subHours(new Date(), this.maxVisitDurationHours) > lastVisitDate) {
          this.incrementNumVisits()
        }

      } else {

        this.incrementNumVisits()
      }

      this.storage.setItem('lastVisitDate', new Date().toISOString())
    }
  }

  public getVisitData(): VisitsData | undefined {

    if (this.storage) {

      const lastVisitDate = this.getLastVisitDate()

      let daysSinceLastVisit = 0

      if (lastVisitDate) {

        daysSinceLastVisit = differenceInDays(new Date(), lastVisitDate)
      }

      const numVisits = this.getNumVisits()

      return {
        numVisits,
        hasLoggedInBefore: this.hasLoggedInBefore(),
        daysSinceLastVisit,
        lastVisitDate,
      }
    }
  }

  private getNumVisits(): number {
    const numVisits = this?.storage?.getItem('numVisits')
    return numVisits ? parseInt(numVisits) : 0
  }

  private incrementNumVisits(): void {
    const numVisits = this.getNumVisits()
    this?.storage?.setItem('numVisits', (numVisits + 1).toString())
  }

  public getLastVisitDate(): Date | undefined {
    const lastVisitDate = this?.storage?.getItem('lastVisitDate')
    return lastVisitDate ? new Date(lastVisitDate) : undefined
  }

  private hasLoggedInBefore(): boolean {
    return this?.storage?.getItem('lastLoginDate') ? true : false
  }


  /*************************************************************
   * Cart views.
   *************************************************************/

  // public logCartChange() {
  // }

  public logCartView(): void {
    this.incrementNumCartViews()
    this?.storage?.setItem('lastCartViewDate', new Date().toISOString())
  }

  private incrementNumCartViews(): void {
    const numCartViews = this.getNumCartViews()
    this?.storage?.setItem('numCartViews', (numCartViews + 1).toString())
  }

  public getNumCartViews(): number {
    const numCartViews = this?.storage?.getItem('numCartViews')
    return numCartViews ? parseInt(numCartViews) : 0
  }

  public getLastCartViewDate(): Date | undefined {
    const lastCartViewDate = this?.storage?.getItem('lastCartViewDate')
    return lastCartViewDate ? new Date(lastCartViewDate) : undefined
  }

  public logAddToCart(ticketsAdded: Array<{ ticket: CartTicket, qty: number}>) {

    const effectValues: Array<string> = []
    const guestsQuantities:Array<string> = []
    const dateIn:Array<string> = []
    const dateOut:Array<string> = []
    let ticketCategory;
    let roomSKU;

    for (const row of ticketsAdded){

      const ticket = row.ticket
      let ticketSku = ticket.data.model
      const ticketPrimaryCategory = this.getTicketPrimaryCategory(ticket.title, Object.getOwnPropertyNames(ticket.attributes))
      ticketCategory = this.getTicketPrimaryCategory(ticket.title, Object.getOwnPropertyNames(ticket.attributes))
      const ticketYear = ticket.year ? ticket.year : ticket.date.substring(0, 4)

      if (ticketPrimaryCategory === "HOTEL"){
        ticketSku = ticket.data.attraction_title
        guestsQuantities.push('Adults: ' + ticket.attributes['48'], `Children: ${ticket.attributes['49'][0] + ticket.attributes['87'][0]}`)
        dateIn.push(ticket.attributes['5'][0])
        dateOut.push(ticket.attributes['42'][0])
        const roomPackageID =Object.keys(ticket.data.room.packages)[0]
        roomSKU = ticket.data.room.packages[roomPackageID].sku
      }
      effectValues.push((roomSKU ? `${roomSKU} | ` : '') + ticketSku + `${roomSKU ? ' : ' : ' | '}` + ticket.title + ' | ' + ticketPrimaryCategory + ' | ' + ticket.date + ' | ' + ticketYear + ' | ' + row.qty + ' | ' + (Number(ticket.price) * row.qty).toFixed(2))
    }

    const event: DataLayerEventType = {
      category: {
        primaryCategory: this.getCurrentPageCategory(),
        subCategory1: '',
      },
      eventInfo: {
        effect: effectValues.join(";"),
        eventAction: "add",
        eventName: "Add To Cart",
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Cart",
      }
    }
    if(ticketCategory === 'HOTEL') {
      event.eventInfo.guestsQty = guestsQuantities.join(',')
      event.eventInfo.dateSelectedIn = dateIn.join('')
      event.eventInfo.dateSelectedOut = dateOut.join('')
    }
    this.logEvent(event)
  }

  public logRemoveFromCart(ticketsRemoved: Array<{ ticket: CartTicket, qty: number}>) {

    const effectValues: Array<string> = []
    const guestsQuantities:Array<string> = []
    const dateIn:Array<string> = []
    const dateOut:Array<string> = []
    let ticketCategory;
    let roomSKU;

    for (const row of ticketsRemoved) {
      const ticket = row.ticket
      let ticketSku = ticket.data.model
      const ticketPrimaryCategory = this.getTicketPrimaryCategory(ticket.title, Object.getOwnPropertyNames(ticket.attributes))
      ticketCategory = this.getTicketPrimaryCategory(ticket.title, Object.getOwnPropertyNames(ticket.attributes))
      const ticketYear = ticket.year ? ticket.year : ticket.date.substring(0, 4)

      if (ticketPrimaryCategory === "HOTEL"){
        ticketSku = ticket.data.attraction_title
        guestsQuantities.push('Adults: ' + ticket.attributes['48'], `Children: ${ticket.attributes['49'][0] + ticket.attributes['87'][0]}`)
        dateIn.push(ticket.attributes['5'][0])
        dateOut.push(ticket.attributes['42'][0])
        const roomPackageID =Object.keys(ticket.data.room.packages)[0]
        roomSKU = ticket.data.room.packages[roomPackageID].sku
      }
      effectValues.push((roomSKU ? `${roomSKU} | ` : '') + ticketSku + `${roomSKU ? ' : ' : ' | '}` + ticket.title + ' | ' + ticketPrimaryCategory + ' | ' + ticket.date + ' | ' + ticketYear + ' | ' + row.qty + ' | ' + (Number(ticket.price) * row.qty).toFixed(2))
    }

    const event: DataLayerEventType = {
      category: {
        primaryCategory: this.getCurrentPageCategory(),
        subCategory1: '',
      },
      eventInfo: {
        effect: effectValues.join(";"),
        eventAction: "remove",
        eventName: "Remove From Cart",
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Cart",
      }
    }
    if(ticketCategory === 'HOTEL') {
      event.eventInfo.guestsQty = guestsQuantities.join(',')
      event.eventInfo.dateSelectedIn = dateIn.join('')
      event.eventInfo.dateSelectedOut = dateOut.join('')
    }
    this.logEvent(event)
  }

  public logModalAction(modalAction:string,subCategory1:string,eventName:string){
    const event: DataLayerEventType = {
      category: {
        primaryCategory: this.getCurrentPageCategory(),
        subCategory1: subCategory1,
      },
      eventInfo: {
        effect: 'OK',
        eventAction: modalAction,
        eventName: eventName,
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Modal",
      }
    }
    this.logEvent(event)
  }

  public logSearchFormSubmit(hotelSearchRooms:HotelSearchRoom[],datesSearch:Date[], pageName:string){

    const searchSubmitEvent: DataLayerEventType = {
      category: {
        primaryCategory: "Web Store",
        subCategory1: 'Hotels Search Form',
      },
      eventInfo: {
        effect: "OK",
        eventAction: "Hotel Search Form | " + pageName,
        eventName: 'Hotel Search',
        eventPoints: datesSearch.length > 0 ?
          new Date(datesSearch[0]).toISOString() + ' | ' + new Date(datesSearch[1]).toISOString() + ' | ' +
          'Nights: ' + Math.round((datesSearch[1].getTime() - datesSearch[0].getTime()) / (1000 * 3600 * 24)) + ' | ' +
          'Adults: ' + hotelSearchRooms.reduce(function(prevValue,currentValue) { return prevValue + currentValue.adultsQty },0) + ' | ' +
          'Children: ' + (hotelSearchRooms.reduce(function(prevValue,currentValue) { return prevValue + currentValue.childrenQty },0) + hotelSearchRooms.reduce(function(prevValue,currentValue) { return prevValue + currentValue.infantsQty },0)) + ' | ' +
          'Rooms: ' + hotelSearchRooms.length
        : '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Hotels Search",
      }
    }
    this.logEvent(searchSubmitEvent)
  }

  public logModifyCart(cartTicket:CartTicket[], roomNumber:number){
    const effectValues: Array<string> = [];
    const guestsQuantities: Array<string> = [];
    const dateIn: Array<string> = [];
    const dateOut: Array<string> = [];
    let roomNum;
    let roomSKU;

    cartTicket.forEach((ticket: CartTicket) => {
      if (ticket.tags.some((tag: string) => tag.includes("hotels"))) {
        const ticketSku = ticket.data.attraction_title;
        const ticketPrimaryCategory = this.getTicketPrimaryCategory(
          ticket.title,
          Object.getOwnPropertyNames(ticket.attributes)
        );
        const ticketYear = ticket.year
          ? ticket.year
          : ticket.date.substring(0, 4);
        guestsQuantities.push(
          "Adults: " + ticket.attributes["48"],
          `Children: ${ticket.attributes["49"][0] + ticket.attributes["87"][0]}`
        );
        dateIn.push(ticket.attributes["5"][0]);
        dateOut.push(ticket.attributes["42"][0]);
        roomNum = ticket.tags.some((tag: string) =>
          tag.includes(roomNumber.toString())
        );
        const roomPackageID = Object.keys(ticket.data.room.packages)[0];
        roomSKU = ticket.data.room.packages[roomPackageID].sku;

        effectValues.push(
          `${roomSKU} | ` +
            ticketSku +
            " : " +
            ticket.title +
            " | " +
            ticketPrimaryCategory +
            " | " +
            ticket.date +
            " | " +
            ticketYear +
            " | " +
            "1" +
            " | " +
            Number(ticket.price).toFixed(2)
        );
      }
    });
    const event: DataLayerEventType = {
      category: {
        primaryCategory: this.getCurrentPageCategory(),
        subCategory1: "",
      },
      eventInfo: {
        effect: effectValues.join(),
        eventAction: "modify",
        eventName: "Modify Cart",
        eventPoints: "",
        eventTargetUrl: "",
        timeStamp: new Date().toISOString(),
        guestsQty: guestsQuantities.join(","),
        dateSelectedIn: dateIn.join(""),
        dateSelectedOut: dateOut.join(""),
        type: "Cart",
      },
    };
    if (roomNum) this.logEvent(event);
  }

  public logOutEvent(){

    const event: DataLayerEventType = {
      category: {
        primaryCategory: this.getCurrentPageCategory(),
        subCategory1: '',
      },
      eventInfo: {
        effect: 'OK',
        eventAction: "Submit",
        eventName: "Logged out",
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Form",
      }
    }
   this.logEvent(event)
  }

  public loginEvent(sessionContext:SessionType){
    const signIn : DataLayerEventType = {
      category: {
        primaryCategory: "Web Store",
        subCategory1: '',
      },
      eventInfo: {
        effect: "OK",
        eventAction: "Submit",
        eventName: 'Login Form',
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Form",
      }
    }

    if (sessionContext.login_order_id){
      this.logEvent(signIn)
    }
  }

  /*************************************************************
   * Internal referer.
   *************************************************************/

  /** Return referrer domain if it looks like a Universal one, otherwise empty string. */
  public getInternalReferrerDomain(referer: string | undefined): string {
    if (referer) {
      if (referer.includes('universal')) return referer
      if (referer.includes('atdtravel.com')) return referer // Staging
      if (referer.includes('localhost')) return referer // Dev
    }
    return ''
  }

  public getPreviousPageName(): string {
    return this.storage?.getItem('previousPageName') ?? ''
  }

  public logPreviousPageName(pageName: string) {
    return this.storage?.setItem('previousPageName', pageName)
  }


  /*************************************************************
   * Primary category.
   *************************************************************/

  /** Return a "primary category" based on the given ticket info. */
  public getTicketPrimaryCategory(title: string, attributeIds: string[]) {
    if (attributeIds.includes("42")) return 'HOTEL'
    if (title.toLowerCase().includes('express pass')) return 'UEP' // Taken from US site
    if (attributeIds.includes("5")) return 'TICKET_DATED_FUTURE' // Taken from US site
    return 'TICKET'
  }


  /*************************************************************
   * Event logging.
   *************************************************************/

  /** Add an "event" object to digitalData.events[]. */
  public logEvent(event: DataLayerEventType) {
    if (window) {
      window.digitalData = window.digitalData || {}
      window.digitalData.events = window.digitalData.events || []
      window.digitalData.events.push(event)
    }
  }


  /*************************************************************
   * Misc helper functions.
   *************************************************************/

  /** Return the dataLayer category for the current page. */
  private getCurrentPageCategory(): string {
    if (window) {
      return window?.digitalData?.page?.category?.primaryCategory ?? ''
    }
    return ''
  }

  public pageLoadEvent(pageName:string){
    // Page load event:
    const pageLoadEvent: DataLayerEventType = {
      category: {
        primaryCategory: "Web Store",
        subCategory1: '',
      },
      eventInfo: {
        effect: "Page Loaded",
        eventAction: "Load",
        eventName: pageName,
        eventPoints: '',
        eventTargetUrl: '',
        timeStamp: new Date().toISOString(),
        type: "Page",
      }
    }

    // Log page load event just once, even if component is rendered again:
    let logEvent = true
    if (window?.digitalData?.events) {
      const lastPageLoadEvent: DataLayerEventType = [...window.digitalData.events].reverse().find((e: DataLayerEventType) => e.eventInfo.effect === 'Page Loaded' ||  e.eventInfo.eventName === 'Logout')
      if (lastPageLoadEvent && lastPageLoadEvent.eventInfo.eventName === pageLoadEvent.eventInfo.eventName) {
        logEvent = false
      }
    }
    // Need to check for 'interactive' readyState because chrome loads the page before the readyState is 'complete'
    if (document.readyState === "complete" || document.readyState === "interactive") {
      logEvent && this.logEvent(pageLoadEvent)
      logEvent = false
    }
  }
}
