import Image from "next/legacy/image"
import uorLogo from '../../../../images/logos/uor.svg'

export function Logo() {
  return <Image
    alt="Universal Orlando Resort" 
    src={uorLogo}
    priority
  />
}
