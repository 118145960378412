import styles from './Layout.module.scss';

import React from "react";

import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";

export function Layout({ children }: { children: React.ReactNode } ) {

  return (
    <>
      <Header />
      <div className={ styles.container }>
        { children }
      </div>
      <Footer />
    </>
  );
}

