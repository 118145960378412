import { useEffect, useState } from 'react'
import type { ReactElement, ReactNode } from 'react'
import type { NextPage } from 'next'
import type { AppProps, AppContext } from 'next/app'
import App from "next/app"
import Script from 'next/script'

import 'react-calendar/dist/Calendar.css';
import '../styles/calendar.scss'
import '../styles/globals.scss'
import '../styles/layout.scss'
import '../styles/checkoutdotcom.scss'
import '../components/Hotels/modalTabs.scss'

import { handleBodyClassTweaks } from '../cro/handleBodyClassTweaks'

import { Layout } from '../components/Layout/Layout'
import { AdobeTag } from '../components/Analytics/AdobeTag/AdobeTag'
import { ContextWrapper } from '../contexts/ContextWrapper'
import { DataLayerContextShape } from '../contexts/DataLayerContext'
import { getIsActiveTest, getAllCroTests } from '../contexts/ABContext'

// @ts-ignore
import TagManager from 'react-gtm-module-custom-domain'
import Head from 'next/head'

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {

  // Initialise Google Tag Manager on page load:
  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-NN6SGX',
      customURL: 'https://wingate.attractiontickets.com/gtm.js' })
  },[])

  const dataLayerContextProps: DataLayerContextShape = {
    // @ts-ignore
    referer: pageProps.referer,
    // @ts-ignore
    router: pageProps.router,
  }

  // Get ABContext values:
  // @ts-ignore
  const allTests = getAllCroTests(pageProps.croTestsHeader)
  const isActiveTest = getIsActiveTest(allTests)

  // Add classes to <body> tag for active CRO tests:
  useEffect(() => {
    handleBodyClassTweaks(allTests)
  }, [allTests])

  // Use the layout defined at the page level, if available:
  if (Component.getLayout) {
    return Component.getLayout(<Component {...pageProps} />)
  }

  // Otherwise return default layout:
  return (
    <>
      <Head>
        <title>Universal Orlando Resort™ | Your Orlando Vacation Destination</title>
        <link rel="icon" href="favicon.ico" />
      </Head>
      <Script src="https://cdn.checkout.com/js/framesv2.min.js" />
      <Script id='one-trust' src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js" type='text/javascript' charSet='UTF-8' data-domain-script="66dd7bda-9e41-4616-b6de-5517163a3a03-test">
        {function OptanonWrapper() { }}
      </Script>
      <AdobeTag />
      <ContextWrapper dataLayerContextProps={ dataLayerContextProps } isActiveTest={ isActiveTest } allTests={ allTests }>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ContextWrapper>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)

  // Add router to page props:
  appProps.pageProps.router = appContext.router

  // Add referer header page props. NextJS seems to automatically handle this being from the server or client:
  appProps.pageProps.referer = appContext.ctx.req?.headers.referer

  // Add CRO test header to page props, if exists:
  appProps.pageProps.croTestsHeader = appContext.ctx.req?.headers?.['x-cro-tests'] ?? '';

  return { ...appProps  }

}

export default MyApp
