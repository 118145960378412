import React from 'react'

import { SessionAPI } from '../api/SessionAPI'
import { SessionType } from '../types/SessionType'

export type SessionContextShape = { sessionAPI?: SessionAPI, session?: SessionType }

const sessionContextValue: SessionContextShape = { sessionAPI: undefined, session: undefined }
export const SessionContext = React.createContext(sessionContextValue)

