import { useEffect, useState } from 'react';
import { ContentAPI } from '../../../../api/ContentAPI';
import styles from './HeaderPhone.module.scss';


export function HeaderPhone() {

  const [phone, setPhone] = useState<string>('')
  const [openingHours, setOpeningHours] = useState<string>('')

  useEffect(() => {
    const contentApi = new ContentAPI()
    contentApi.get().then(content => {
      setPhone(content.phone)
      setOpeningHours(content.opening_hours)
    })
  }, [])

  return phone && openingHours ? (
    <div className={styles.phone} data-testid='contact-details'>
      <div className={styles.openingHours}>
        <b>CALL ATTRACTION TICKETS LTD</b>
      </div>
      <div className={styles.openingHours}>
        <b>FREE</b> {openingHours}
      </div>
      <a href={`tel:${phone}`} className={styles.number}>{phone}</a>
    </div>
  ) : null
}
