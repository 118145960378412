import { useState } from 'react';
import styles from './TopBar.module.scss';

import { Logo } from './Logo/Logo';
import { HeaderPhone } from './HeaderPhone/HeaderPhone';
import { HeaderLogin } from './HeaderLogin/HeaderLogin';

export function TopBar() {

  const [showDetails, setShowDetails] = useState(false);

  const handleToggleClick = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  return (
    <div className={styles.topBar}> 
      <div className={styles.inner}>
        <a href="#maincontent" className={styles.skipLink}>
          Skip to main content
        </a>
        <a onClick={handleToggleClick}>
        <Logo />
        </a>
        {showDetails === true && (
        <>
          <div className={ styles.popup }>
            <p className={ styles.popupTitle }>Are you sure you want to return Home?</p>
            <div className={styles.popupBtns}>
              <a className={ styles.btn } href='https://www.universalorlando.com/web/en/gb'>Return to home</a>
              <a className={ styles.btnAlt } href='https://ukstore.universalorlando.com'>Continue shopping</a>
            </div>
          </div>
          <div className={ styles.overlay } onClick={handleToggleClick}></div>
        </>
      )}
        <div className={styles.right}>
          <HeaderPhone />
          <HeaderLogin />
        </div>
      </div>
    </div>
  )
}
