export async function httpRequest(method: string, url: string, body?: any, sessionId?: string) {

  const headers = new Headers()
  headers.append('Content-Type', 'application/json')

  if (sessionId) {
    headers.append('Authorization', 'Basic ' + sessionId)
  }

  headers.append('Content-Type', 'application/json')

  const options: RequestInit = {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    headers,
    redirect: 'follow',
    credentials: 'include',
  }

  if (body) options.body = JSON.stringify(body)

  const result = await fetch(url, options)
  return result
}

