/* eslint-disable @next/next/no-sync-scripts */ // * This is for the oneTrust cookie policy script
import { AdobeTag } from '../Analytics/AdobeTag/AdobeTag';
import styles from './Header.module.scss';

import { TopBar } from './TopBar/TopBar';

export function Header() {

  return (
    <header className={styles.header}>
      <TopBar />
    </header>
  );
}
