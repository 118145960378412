// import { NextRouter } from "next/router";
import { createContext, Dispatch, SetStateAction, useState } from "react";

export const Hotel_SlugContext = createContext({} as Hotel_SlugContextType);

export type Hotel_SlugContextType = {
  hotel_slugToContext: string | undefined
  setHotel_SlugToContext: Dispatch<SetStateAction<string| undefined>>,
}

export function Hotel_SlugProvider ({children}:JSX.ElementChildrenAttribute) {

  const [hotel_slugToContext, setHotel_SlugToContextWrapper]= useState<string | undefined>(() => {
    if (typeof window !== 'undefined') {
      try {
        const hotel_slugLocalStorage = window.localStorage.getItem('hotel_slug');
        return hotel_slugLocalStorage ? hotel_slugLocalStorage : '';
      }
      catch (error) {
        return '';
      }
    }
  });

  const SetHotelSlugToLocalStorage = (hotel_slug: string) => {
    if (typeof window !== 'undefined') {
      try {
        hotel_slug ? window.localStorage.setItem('hotel_slug', hotel_slug) : window.localStorage.removeItem('hotel_slug');
      }
      catch (error) {
        console.log(error);
      }
    }
  }
  SetHotelSlugToLocalStorage(hotel_slugToContext ?? '')

  const hotel_slugContext:Hotel_SlugContextType = { hotel_slugToContext, setHotel_SlugToContext: setHotel_SlugToContextWrapper};

  return (
    <Hotel_SlugContext.Provider value={hotel_slugContext}>
      {children}
    </Hotel_SlugContext.Provider>
  )
}