export function getEnv(): 'dev'|'staging'|'live'|null {

  if (typeof(window) === 'undefined') return null

  if (window.location.host.includes('.test')) return 'dev'
  if (window.location.host.includes('localhost')) return 'dev'
  if (window.location.host.includes('atdtravel.com')) return 'staging'

  return 'live'
}
