import React, { PropsWithChildren } from 'react'

import type { CroTest } from '../types/CroTestType'

export type IsActiveTestType = (testName: string) => boolean;

export type ABContextShape = {
  isActiveTest: IsActiveTestType,
  allTests: CroTest[],
}

export const ABContext = React.createContext<ABContextShape>({
  isActiveTest: () => false,
  allTests: [],
})

export function ABContextProvider ({ isActiveTest, allTests, children }: PropsWithChildren<ABContextShape>) {
  return (
    <ABContext.Provider value={{ isActiveTest, allTests }}>
      { children }
    </ABContext.Provider>
  )
}


/** Parse cron tests request header string and return array of cro tests. */
export function getAllCroTests(croTestsHeader: string): CroTest[] {

  // Parse the tiven croTestsHeader value to see which tests are running and get the current user's status for each test.
  const tests: CroTest[] = []
  croTestsHeader.split(",").forEach(str => {
    const tmp = str.split(":");
    if (tmp.length === 2 && tmp[0].match(/^[A-Za-z0-9_-]+$/) && (tmp[1] === "0" || tmp[1] === "1")) {
      tests.push({ name: tmp[0], active: tmp[1] === "1" ? 1 : 0 })
    }
  })
  return tests;
}

/** Return a function which checks if a given test name is active or not. */
export function getIsActiveTest(tests: CroTest[]): (testName: string) => boolean {

  return (testName: string) => tests.some(test => test.name === testName && test.active === 1)
}


