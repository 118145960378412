import { httpRequest } from "./httpRequest";
import { ContentType } from '../types/ContentType'

export class ContentAPI {

    public async get(): Promise<ContentType> {

        let res = await httpRequest('GET', `${process.env.NEXT_PUBLIC_API_BASE_URL}/content`)

        const json = await res.json()

        const content: ContentType = {
            phone: json.phone,
            opening_hours: json.opening_hours,
            gtm_Account: json.gtm_Account
        }

        return content

    }
}
