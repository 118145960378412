import React, { PropsWithChildren } from 'react'
import type { NextRouter } from 'next/router'

export type DataLayerContextShape = {
  referer: string,
  router?: NextRouter,
}

export const DataLayerContext = React.createContext<DataLayerContextShape>({
  referer: '',
  router: undefined,
})

export function DataLayerContextProvider ({ referer, router, children }: PropsWithChildren<DataLayerContextShape>) {

  return (
    <DataLayerContext.Provider value={{ referer, router }}>
      { children }
    </DataLayerContext.Provider>
  )
}
