
/**
 * {
 *   "id": "4195e692a62654866b2af316133bcb7a",
 *   "user_id": "12341234",
 *   "username": "john.smith",
 *   "email": "john.smith@example.com"
 * }
 */

export interface SessionType {
  id: string,
  user_id: string,
  username: string,
  email: string,
  login_order_id: string,
  loading: boolean;
}

export const SessionDefaultState = {
  id: '',
  user_id: '0',
  username: 'anonymous',
  email: '',
  login_order_id: '',
  loading: false,
}
