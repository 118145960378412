import React, { PropsWithChildren, useState, Dispatch, SetStateAction } from 'react'

type contextShape = {
  year?: string,
  setYear?: Dispatch<SetStateAction<string>>
}

export const YearContext = React.createContext<contextShape>({
  year: undefined,
  setYear: undefined,
})


export function YearContextProvider ({ children }: PropsWithChildren<{}>) {
  const [year, setYear] = useState(new Date().getFullYear().toString())

  return (
    <YearContext.Provider value={{ year, setYear }}>
      { children }
    </YearContext.Provider>
  )
}
